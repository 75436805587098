let en = {
  phone: 'Contact us through Whatsapp or SMS at +52 (638) 110 - 7587',
  company: 'Desert By The Sea Rentals™',
  social: 'Visit our social media:'
};

let es = {
  phone: 'Contactanos por Whatsapp o SMS al +52 (638) 110 - 7587',
  company: 'Desert By The Sea Rentals™',
  social: 'Visita nuestras redes sociales:'
};

export default {
  default: en,
  es,
  en
};
