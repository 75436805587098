let en = {
  title: 'About us',
  paragraph: 'Desert By The Sea is owned and operated by Zaadia Marquez and Humberto Hernandez.'
};
let es = {
  title: 'Acerca de nosotros',
  paragraph: 'Desert By The Sea es una empresa familiar manejado y operado por Zaadia Márquez y Humberto Hernández.'
};
export default {
  default: en,
  es,
  en
};
