import React from 'react';
import '../../App/App.css';

function Contact() {
  return (
    <div className="Main Body">
      <h1 className="text greeting">Contact</h1>
      <p className="text paragraph center">Email: desertbythesearentals@gmail.com</p>
    </div>
  );
}

export default Contact;
