import Condos from './Condos';
import About from './About';
import Contact from './Contact';
import Home from './Home';

export default {
  Condos,
  About,
  Contact,
  Home
};
